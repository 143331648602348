import { WorkRecord } from '../../../../interfaces/WorkRecord';
import {
  SelectValueInterface,
  defaultSelectValue,
  SelectKeyValueI,
  defaultSelectKeyValue
} from '../../../../interfaces/form/SelectValueInterface';
import ValueDictionary from '../../../../models/ValueDictionary';
import ValueLocation from '../../../../models/ValueLocation';
import ValueStaff from '../../../../models/ValueStaff';
import ValueUnit from '../../../../models/ValueUnit';

export interface PositionDescriptionStateInterface {
  employment: SelectValueInterface;
  startDate: Date | null;
  endDate: Date | null;
  position: SelectValueInterface;
  seniority: SelectKeyValueI;
  unit: SelectValueInterface;
  location: SelectValueInterface;
  costLocation: SelectValueInterface;
  isBillable: boolean;
  billingLevel: SelectValueInterface;
  lineManager: SelectValueInterface;
  mentor: SelectValueInterface;
  trialPeriod: boolean;
  trialEndDate: Date | null;
  jiraRecId: string;
  recruitmentId: string;
  peoplePartner: SelectValueInterface;
  consultancyRanking: SelectValueInterface;
  cohort: SelectValueInterface;
  maxTotalLoad: number;
  maxBillableLoad: number;
  applyChanges: Date;
  costCenter: SelectValueInterface;
}

export const initialState : PositionDescriptionStateInterface = {
  employment: defaultSelectValue,
  startDate: null,
  endDate: null,
  position: defaultSelectValue,
  seniority: defaultSelectKeyValue,
  unit: defaultSelectValue,
  location: defaultSelectValue,
  costLocation: defaultSelectValue,
  isBillable: false,
  billingLevel: defaultSelectValue,
  lineManager: defaultSelectValue,
  mentor: defaultSelectValue,
  trialPeriod: false,
  trialEndDate: null,
  jiraRecId: '',
  recruitmentId: '',
  peoplePartner: defaultSelectValue,
  consultancyRanking: defaultSelectValue,
  cohort: defaultSelectValue,
  maxTotalLoad: 0,
  maxBillableLoad: 0,
  applyChanges: new Date(),
  costCenter: defaultSelectValue
}

export default class PositionDescriptionState {
  public static generateFromWorkRecord(workRecord?: WorkRecord): PositionDescriptionStateInterface {
    let state = initialState;
    if (workRecord) {
      const employment = workRecord.employment as ValueDictionary;
      state.employment = employment ? { id: employment.id, name: employment.value } : defaultSelectValue;
      state.startDate = workRecord.startDate;
      if (state.startDate && state.applyChanges < state.startDate) {
        state.applyChanges = state.startDate;
      }
      state.endDate = workRecord.endDate as Date;
      if (state.endDate && state.applyChanges > state.endDate) {
        state.applyChanges = state.endDate;
      }
      state.trialPeriod = workRecord.isTrial;
      let trialEndDate = null;
      if (workRecord.trialEndDate) {
        trialEndDate = workRecord.trialEndDate;
      } else if (workRecord.startDate) {
        trialEndDate = new Date(workRecord.startDate.getTime());
        trialEndDate.setDate(trialEndDate.getDate() + 91);
      }
      state.trialEndDate = trialEndDate;
      const position = workRecord.position as ValueDictionary;
      state.position = (position) ? {id: position.id, name: position.value} : defaultSelectValue;
      const seniority = workRecord.seniority.value;
      state.seniority = seniority ? { id: seniority, name: seniority} : defaultSelectKeyValue;
      const unit = workRecord.unit as ValueUnit;
      state.unit = (unit && unit.id) ? {id: unit.id, name: unit.value} : defaultSelectValue;
      const location = (workRecord.location as ValueLocation);
      state.location = (location && location.id) ? {id: location.id, name: location.value + ', ' + location.country} : defaultSelectValue;
      const costLocation = (workRecord.costLocation as ValueLocation);
      state.costLocation = (costLocation && costLocation.id) ? {id: costLocation.id, name: costLocation.value + ', ' + costLocation.country} : defaultSelectValue;
      state.isBillable = workRecord.isBillable.value;
      const billingLevel = workRecord.billingLevel as ValueDictionary;
      state.billingLevel = billingLevel ? { id: billingLevel.id, name: billingLevel.value } : defaultSelectValue;
      const lineManager = workRecord.lineManager as ValueStaff;
      state.lineManager = (lineManager && lineManager.staffId)
        ? {id: lineManager.staffId, name: lineManager.staffFullName} : defaultSelectValue;
      const mentor = workRecord.mentor as ValueStaff;
      state.mentor = (mentor?.staffId) ? {id: mentor.staffId, name: mentor.staffFullName} : defaultSelectValue;
      state.jiraRecId = workRecord.jiraRecId.value;
      state.recruitmentId = workRecord.recruitmentId.value;
      const peoplePartner = workRecord.peoplePartner as ValueStaff;
      state.peoplePartner = (peoplePartner && peoplePartner.staffId)
        ? {id: peoplePartner.staffId, name: peoplePartner.staffFullName} : defaultSelectValue;
      const consultancyRanking = workRecord.consultancyRanking as ValueDictionary;
      state.consultancyRanking = consultancyRanking ? { id: consultancyRanking.id, name: consultancyRanking.value } : defaultSelectValue;
      const cohort = workRecord.cohort as ValueDictionary
      state.cohort = cohort ? { id: cohort.id, name: cohort.value } : defaultSelectValue;
      state.maxTotalLoad = workRecord.maxTotalLoad.value;
      state.maxBillableLoad = workRecord.maxBillableLoad.value;
      const costCenter = workRecord.costCenter as ValueDictionary;
      state.costCenter = (costCenter) ? {id: costCenter.id, name: costCenter.value} : defaultSelectValue;
    }
    return state;
  }
}
