import { WorkRecord as WorkRecordInterface } from '../interfaces/WorkRecord';
import {Value} from '../interfaces/Value';
import ValueString from '../models/ValueString';
import ValueBoolean from '../models/ValueBoolean';
import ValueNumber from '../models/ValueNumber';
import ValueDictionary from '../models/ValueDictionary';
import ValueStaff from '../models/ValueStaff';
import ValueLocation from '../models/ValueLocation';
import ValueUnit from '../models/ValueUnit';
import DateHelper from '../utils/date/DateHelper';
import { STATUSES } from '../constants';

export default class WorkRecord implements WorkRecordInterface {
  id: number = NaN;
  staffId: number = NaN;
  status: string = '';
  code: string = '';
  startDate: Date | null = null;
  endDate: Date | null = null;
  trialStatus: string = '';
  trialEndDate: Date | null = null;
  tenure: number = NaN;

  isTrial: boolean = true;
  isInactive: boolean = false;
  attributes: Map<string, any> = new Map();
  attributesFuture: Map<string, any> = new Map();

  billingLevel: Value<string> = new ValueDictionary();
  bonus: Value<string> = new ValueString();
  mentor: ValueStaff = new ValueStaff();
  employment: Value<string> = new ValueString();
  consultancyRanking: Value<string> = new ValueDictionary();
  isBillable: Value<boolean> = new ValueBoolean();
  jiraRecId: Value<string> = new ValueString();
  recruitmentId: Value<string> = new ValueString();
  lineManager: ValueStaff = new ValueStaff();
  location: ValueLocation = new ValueLocation();
  costLocation: ValueLocation = new ValueLocation();
  maxBillableLoad: Value<number> = new ValueNumber(100);
  maxTotalLoad: Value<number> = new ValueNumber(0);
  nextEmployer: Value<string> = new ValueString();
  payments: Value<string> = new ValueString();
  peoplePartner: ValueStaff = new ValueStaff();
  performance: Value<string> = new ValueString();
  position: Value<string> = new ValueDictionary();
  reasonToLeave: Value<string> = new ValueDictionary();
  seniority: Value<string> = new ValueString();
  turnover: Value<string> = new ValueString();
  unit: ValueUnit = new ValueUnit();
  costCenter: Value<string> = new ValueDictionary();
  cohort: Value<string> = new ValueDictionary();

  getDates(): ValueString {
    let dates: string = '';
    if (this.startDate) {
      if (this.status === STATUSES.OFFER_ACCEPTED) {
        dates += 'will start on ' + DateHelper.getFormattedDate(this.startDate);
      } else {
        dates += DateHelper.getFormattedDate(this.startDate);
        dates += ' - ';
        dates += (this.endDate) ? DateHelper.getFormattedDate(this.endDate) : 'Now';
      }
    }

    return new ValueString(dates);
  }
}
