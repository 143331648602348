import { Skill } from '../../../../interfaces/entity/Skill'
import { WorkRecord } from '../../../../interfaces/WorkRecord'

export interface SkillEditStateInterface {
  skills: Array<Skill>,
  activeFrom: Date
}

const initialState: SkillEditStateInterface = {
  skills: [],
  activeFrom: new Date()
}

export default class SkillEditState {
  public static generateFromList(list: Array<Skill>, wr: WorkRecord): SkillEditStateInterface {
    let state = initialState
    state.skills = list
    if (wr.startDate && state.activeFrom < wr.startDate) {
      state.activeFrom = wr.startDate
    }
    if (wr.endDate && state.activeFrom > wr.endDate) {
      state.activeFrom = wr.endDate
    }
    return state
  }
}
