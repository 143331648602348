import * as Yup from 'yup';

const dictionaryTransform = (currentV: any, originalV: any) => (originalV?.id && !isNaN(originalV.id)) ? originalV.id : null;

export const schema = Yup.object()
  .shape({
    employment: Yup.number()
      .required('Employment is required')
      .transform(dictionaryTransform),
    startDate: Yup.date()
      .typeError('Start Date must be set')
      .required('Start Date is required'),
    endDate: Yup.date()
      .nullable()
      .notRequired()
      .test('min', 'Trial End Date can\'t be less than Start Date', function (value) {
        const { startDate } = this.parent;
        if (startDate && value && value < startDate) {
          return this.createError({
            message: 'End Date can\'t be less than Start Date',
            path: 'endDate',
          });
        }
        return true;
      }),
    position: Yup.number()
      .typeError('Position must be set')
      .required('Position is required')
      .transform(dictionaryTransform),
    seniority: Yup.string()
      .nullable()
      .transform(dictionaryTransform),
    unit: Yup.number()
      .typeError('Org. Unit must be set')
      .required('Org. Unit is required')
      .transform(dictionaryTransform),
    location: Yup.number()
      .typeError('Location must be set')
      .required('Location is required')
      .transform(dictionaryTransform),
    costLocation: Yup.number()
      .typeError('Cost Location must be set')
      .required('Cost Location is required')
      .transform(dictionaryTransform),
    isBillable: Yup.boolean(),
    billingLevel: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    lineManager: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    mentor: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    trialPeriod: Yup.boolean(),
    trialEndDate: Yup.date()
      .when('trialPeriod', {
        is: true,
        then: (schema) => schema.required('Trial End Date is required'),
        otherwise: (schema) => schema.nullable()
      })
      .test('min', 'Trial End Date can\'t be less than Start Date', function (value) {
        const { startDate } = this.parent;
        if (startDate && value && value < startDate) {
          return this.createError({
            message: 'Trial End Date can\'t be less than Start Date',
            path: 'trialEndDate',
          });
        }
        return true;
      })
      .test('max', 'Trial End Date can\'t be greater than End Date', function (value) {
        const { endDate } = this.parent;
        if (endDate && value && value > endDate) {
          return this.createError({
            message: 'Trial End Date can\'t be greater than End Date',
            path: 'trialEndDate',
          });
        }
        return true;
      }),
    jiraRecId: Yup.string(),
    recruitmentId: Yup.string(),
    peoplePartner: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    consultancyRanking: Yup.number()
      .nullable()
      .transform(dictionaryTransform),
    cohort: Yup.number()
      .nullable()
      .transform(dictionaryTransform)
      .test(
        'required',
        'Cohort cannot be defined without Consultancy Ranking',
        function (val: any) {
          if (!this.parent.consultancyRanking && val) {
            return this.createError({
              message: 'Cohort cannot be defined without Consultancy Ranking',
              path: 'cohort',
            });
          }
          return true
        }
      ),
    maxTotalLoad: Yup.number(),
    maxBillableLoad: Yup.number(),
    costCenter: Yup.number()
      .typeError('Cost Center must be set')
      .required('Cost Center is required')
      .transform(dictionaryTransform),
  })
